import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import styled from 'styled-components';
import { 
  type_title, 
  type_title_sm, 
  type_title_md,
  type_general, 
  type_general_lg, 
  type_body, 
  type_body_sm, 
  type_body_bold,
  breakpoint, 
  margin, 
  headerHeight,
  baseColor, 
  greyColor, 
  blackColor, 
  grid, 
  colorTransition 
} from '../styles/helpers'
import { 
  PageWrap, 
  PageNav, 
  PageNavItem, 
  PageWrapInner, 
  PageEntry, 
  PageEntryInner, 
  PageEntryMark 
} from '../styles/page'

import Image from '../components/image'
import Layout from "../components/layout"
import Time from '../utils/time'
import SEO from "../components/seo"
import Img from "gatsby-image"
import urlBuilder from "@sanity/image-url"
import { getFluidGatsbyImage, getFixedGatsbyImage } from 'gatsby-source-sanity'


const ProjectsIndex = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  overflow: scroll;
  justify-content: space-between;
  align-items: center;
  // margin: 0 auto;
  // ...added
  height: 100%;
  // ..added 
  padding: 0 ${margin} 0 ${margin};
  @media ${breakpoint.xs} {
    padding: unset;
  }
`

const ProjectCell = styled.div`
  flex-shrink: none;
  // margin-bottom: 5rem;
  // width: calc(50% - 2.5rem);
  margin: 0 auto 5rem;
  width: calc(33.3% - 2.5rem);
  max-width: 420px;
  color: black;
  & a {
    color: inherit;
  }
  @media ${breakpoint.xs} {
    width: 100%;
    margin-bottom: 5rem;
  }
  // temp -- will be removed when there are no repeat projects in sanity
  &:last-child {
    display: none;
  }
`

const ProjectCellHeader = styled.div`
  text-align: center;
  display: flex;
  flex-flow: column;
  & span.title {
    ${type_title_md};
    margin-bottom: .125rem;
    @media ${breakpoint.xs} {
      ${type_title};
    }
  }
  & span.year {
    ${type_general};
    margin-bottom: .125rem;
    @media ${breakpoint.xs} {
      margin-bottom: .2rem;
      ${type_general_lg};
    }
  }
  & span.tags {
    ${type_body_sm};
    margin-bottom: 1.25rem;
  }
`

const ProjectCellBody = styled.div`
  & p {
    ${type_body_sm};
  }
`

// todo: sort images, should not have to overwrite css properties given
// to image from gatsby-image
const ProjectCellThumbnail = styled.figure`
  height: 7.15rem;
  margin-bottom: 1rem;
  & div {
    height: 100%;
    & picture {
      & img {
        object-fit: contain !important;
      }
    }
  }
`

const ComingSoonMsg = styled.div`
  ${type_body};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin-top: -${headerHeight};
`

export const query = graphql`
  {
    allSanityProject {
      edges {
        node {
          id
          slug {
            _key
            _type
            current
          }
          year
          date_ended
          date_started
          title
          blurb
          tags_overview
          tags_search
          _rawSlug
          _key
          thumbnail {
            _key
            _type
            _rawAsset
            _rawHotspot
            _rawCrop
          }
        }
      }
    }
  }
`


const ProjectsPage = ({ data, location, ...props }) => {
  
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [filteredQuery, setFilteredQuery] = useState('')


  useEffect(() => {
  //  console.log('projects page mounted', data)
  }, [])

  const handleMouseOver = () => {
    setFiltersOpen(true)
  }

  const handleMouseLeave = () => {
    setFiltersOpen(false)
  }

  const handleFilterClick = (str) => {
    filteredQuery === str ? setFilteredQuery('') : setFilteredQuery(str)
  }

  const projectFilters = filtersOpen ? 
  (
    <PageNav sticky={true} onMouseLeave={handleMouseLeave}>
      <PageNavItem projectIndex={true} isProject={false} title={true}>Sort</PageNavItem>
      <PageNavItem projectIndex={true} isProject={false} firstItem={false} isActive={filteredQuery === 'past'} onClick={(event) => handleFilterClick('past')}>Past</PageNavItem>
      <PageNavItem projectIndex={true} isProject={false} firstItem={false} isActive={filteredQuery === 'present'} onClick={(event) => handleFilterClick('present')}>Present</PageNavItem>
      <PageNavItem projectIndex={true} isProject={false} firstItem={false} isActive={filteredQuery === 'future'}  onClick={(event) => handleFilterClick('future')}>Future</PageNavItem>
    </PageNav>
  )
  :
  (
    <PageNav onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <PageNavItem isProject={false} title={true}>Sort</PageNavItem>
    </PageNav>
  )

  const Thumbnail = (id) => {
    // todo: move config data into .env file if still in use
    const sanityConfig = { projectId: 'ul6999hy', dataset: 'production' }
    const fluidProps = getFluidGatsbyImage(id, { maxWidth: 2000, quality: 100 }, sanityConfig)
    const src = urlBuilder(sanityConfig).image(id).url()
    return <Img style={{ marginBottom: '.5em', objectFit: 'contain' }} fluid={fluidProps} />
  }

  const filteredProjects = data.allSanityProject.edges.filter((project, key) => {
    const dateStarted = Time.formatDate(project.node.date_started)
    const dateEnded = Time.formatDate(project.node.date_ended)
    if (filteredQuery === 'past') {
      if (Time.checkPast(dateEnded)) {
        return project 
      }
    }
    else if (filteredQuery === 'present') {
      if (Time.checkPresent(dateStarted, dateEnded)) {
        return project 
      }  
    }
    else if (filteredQuery === 'future') {
      if (Time.checkFuture(dateStarted)) {
        return project 
      }
    }
    else {
      return project
    }
  })

  return (
    <>
      <SEO title="the dormant office" />
      <PageWrap fixed={props.mobile ? false : true}>
        {/* turned off for now -- need to make conditional */}
        {/* {projectFilters} */}
        <PageWrapInner columnWidth={12}>
          <ComingSoonMsg>Coming Soon</ComingSoonMsg>
          {/* <ProjectsIndex>
            {
              filteredProjects.map((project, key) => (
                <ProjectCell>
                  <Link to={`/projects/${project.node.slug.current}`}>
                    <ProjectCellHeader>
                      <span class = 'title'>{ project.node.title }</span>
                      <span class = 'year'>{ project.node.year }</span>
                      <span class = 'tags'>{ project.node.tags_overview.join(', ') }</span>
                    </ProjectCellHeader>
                    <ProjectCellBody>
                      <p class = 'blurb'>{ project.node.blurb }</p>
                    </ProjectCellBody>
                  </Link>
                </ProjectCell>
              ))
            }
          </ProjectsIndex> */}
        </PageWrapInner>
      </PageWrap>
    </>
  )
}

export default ProjectsPage
